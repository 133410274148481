import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

import Particle1 from "./assets/particle1.png";
import Particle2 from "./assets/particle2.png";
import Particle3 from "./assets/particle3.png";
import Particle4 from "./assets/particle4.png";
import Particle5 from "./assets/particle5.png";
import Particle6 from "./assets/particle6.png";
import Particle7 from "./assets/particle7.png";
import Particle8 from "./assets/particle8.png";
import Particle9 from "./assets/particle9.png";

const ParticlesBackground = () => {
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    await loadSlim(engine);
  }, []);
  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);

    return (
        <Particles
        className="particle"
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          particles: {
            number: {
              value: 14,
              density: { enable: true, area: 1400 },
            },

            shape: {
              type: ['images'],
              images: [
                {
                  src: `${Particle1}`,
                  width: 34,
                  height: 25,
                },
                {
                  src: `${Particle2}`,
                  width: 29,
                  height: 45,
                },
                {
                  src: `${Particle3}`,
                  width: 34,
                  height: 24,
                },
                {
                  src: `${Particle4}`,
                  width: 28,
                  height: 40,
                },
                {
                  src: `${Particle5}`,
                  width: 22,
                  height: 20,
                },
                {
                  src: `${Particle6}`,
                  width: 26,
                  height: 40,
                },
                {
                  src: `${Particle7}`,
                  width: 22,
                  height: 20,
                },
                {
                  src: `${Particle8}`,
                  width: 22,
                  height: 20,
                },

                {
                  src: `${Particle9}`,
                  width: 50,
                  height: 24,
                },
              ],
            },
            // opacity: {
            //   value: 0.17626369048095938,
            //   random: true,
            //   anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
            // },
            size: {
              value: 10,
              random: false,
            },
            line_linked: {
              enable: false,
            },
            move: {
              enable: true,
              speed: 1.5,
              direction: 'none',
              random: false,
              straight: false,
              outModes: {
                default: "bounce",
              },
              attract: { enable: true, rotateX: 100, rotateY: 400 },
            },
          },
          retina_detect: true,
        }}
      />
    )
}

export default ParticlesBackground;